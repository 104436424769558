/*
###############

TEXT-FIELD COMPONENT

###############
*/

.text-field {
    @apply pt-2;
}

.text-field input {
    @apply appearance-none block w-full px-2 rounded-[0.313rem] placeholder-grey-600 focus:outline-none text-sm text-gray h-[34px] border-black border-opacity-30;
}

.text-field:is(.table-input) input {
    @apply border-none;
}

.text-field input::placeholder {
    @apply font-normal text-sm text-black opacity-40;
}

.text-field:not(.disable-read-only-style) input:read-only {
    @apply bg-gray-200 border-transparent;
}

.text-field.md input {
    @apply leading-4;
    min-height: 41px;
}

.text-field.lg input {
    @apply h-full;
}

.text-field.sm input {
    @apply px-1 py-1 text-xs;
}

.text-field.compact input {
    @apply text-xs px-1 py-0 h-25 min-h-0 #{!important};
}

.text-field input:disabled {
    @apply text-gray-750 bg-gray-200 border-transparent #{!important};
}

.text-field label {
    @apply text-sm font-normal text-gray-600 pb-1 flex flex-col;
}

.text-field .left-icon {
    @apply absolute pl-3 top-0 flex h-full items-center justify-center text-gray-600;
}

.text-field .right-icon {
    @apply absolute pr-3 top-0 right-0 flex h-full items-center justify-center text-gray-600;
}

.text-field.sm .left-icon {
    @apply absolute pl-2 top-0 flex h-full items-center justify-center text-gray-600;
}

.text-field.sm .right-icon {
    @apply absolute pr-2 top-0 right-0 flex h-full items-center justify-center text-gray-600;
}

.text-field.sm.icon div input {
    @apply pl-6 #{!important};
}

.text-field.sm.has-right-icon div input {
    @apply pr-6 #{!important};
}

.text-field.icon div input {
    @apply pl-10 #{!important};
}

.text-field.has-right-icon div input {
    @apply pr-8 #{!important};
}

.text-field.icon .left-icon *,
.text-field.has-right-icon .right-icon * {
    @apply h-4 w-4;
}

.text-field .text-field-message {
    @apply text-gray-500 text-xs mt-1;
}

.text-field.invalid .text-field-message {
    @apply text-danger;
}

/*
TEXT FIELD PRIMARY COLORS
*/
.text-field.primary input {
    @apply focus:ring-primary;
    @apply focus:border-primary;
}

/*
TEXT FIELD SECONDARY COLORS
*/
.text-field.secondary input {
    @apply focus:ring-secondary;
    @apply focus:border-secondary;
}

/*
Textfield validation error
 */
.text-field.invalid input {
    @apply border-2 border-danger ring-danger focus:border-danger focus:ring-danger;
}

.text-field.invalid label {
    @apply text-danger;
}
