@import 'simplebar-react/dist/simplebar.min.css';

@import 'tailwindcss/base';
@import 'tailwind/base';

@import 'tailwindcss/components';
@import 'tailwind/components';

@import 'tailwindcss/utilities';
@import 'tailwind/utilities';

@media print {
    html,
    body {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
        background-color: white;
    }

    .show-only-in-pdf {
        display: block !important;
    }

    .hide-only-in-pdf {
        display: none !important;
    }
}

body {
    background-image: linear-gradient(0deg, rgb(0 0 0 / 10%) 0%, rgba(255, 255, 255, 0) 50%), url(/shared/bgtile.png);
    background-attachment: fixed;
    background-size: auto;
}

.show-only-in-pdf {
    display: none;
}

.hide-only-in-pdf {
    display: block;
}

.pdf-options {
    @apply h-full;
    width: 210mm;
    margin: 0 auto !important;

    thead {
        display: table-header-group;
    }

    tfoot {
        display: table-footer-group;
    }

    table {
        page-break-after: avoid;
    }
}

.sub-group {
    border-top: 1px solid gray;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
}

.main-group {
    border: 1px solid gray;
}
