.demand-create-items-table {
    &.left-content::after {
        left: var(--demand-create-items-table--left-offset);
    }

    .text-field {
        input {
            @apply focus:border-[#6ECE5E] focus:ring-transparent;
        }
    }

    .checkbox {
        input {
            @apply focus:border-[#6ECE5E] focus:ring-transparent #{!important};
        }
    }

    .textarea {
        textarea {
            @apply focus:border-[#6ECE5E] focus:ring-transparent;
        }
    }

    .selector-container {
        .selector__control {
            &.selector__control--is-focused {
                @apply border-[#6ECE5E] ring-transparent #{!important};
            }
        }
    }
}

.array-field-grid {
    @apply flex flex-col;
    @apply w-[11.25rem] h-[13.75rem];
    @apply border-gray-500 border-l border-t;

    .array-field-grid-row {
        @apply flex flex-1;
    }

    .array-field-grid-item {
        @apply flex flex-1 justify-center items-center;
        @apply border-gray-500 border-r border-b text-xs;
        @apply text-secondary text-opacity-30;

        &.item-disabled {
            @apply bg-gray-300;
        }

        &.item-active {
            @apply bg-primary text-white #{!important};
        }

        &:not(.item-disabled):hover {
            @apply bg-primary/60 text-white;
        }

        &:focus-visible {
            @apply outline-none bg-primary/60 text-white;
        }
    }
}

.number-attribute-input::-webkit-outer-spin-button,
.number-attribute-input::-webkit-inner-spin-button {
    -webkit-appearance: auto !important;
    margin: 0;
    appearance: auto;
}
