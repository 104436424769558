.selector,
.selector__menu-portal {
    @apply pt-2 text-start;

    .selector-container {
        .selector__control {
            &.selector__control--is-focused {
                @apply ring-primary ring-1;
                @apply border-primary;
            }
        }

        .selector__multi-value {
            @apply bg-primary text-white;
            .selector__multi-value__label {
                @apply text-white;
            }

            .selector__multi-value__remove:hover {
                @apply bg-primary text-white;
            }
        }
    }

    .selector__menu {
        @apply z-50 w-auto whitespace-nowrap min-w-full  mt-[-4px];
        .selector__menu-list {
            .selector__option {
                &.selector__option--is-focused {
                    @apply bg-primary bg-opacity-5 text-black;
                }

                &.selector__option--is-selected {
                    @apply bg-primary text-white;
                }
            }
        }
    }

    label {
        @apply text-sm font-normal text-gray-600 pb-1 flex flex-col;
    }

    .select-field-message {
        @apply text-gray-500 text-xs mt-1;
    }

    &.invalid {
        label {
            @apply text-danger;
        }

        .select-field-message {
            @apply text-danger;
        }

        .selector-container {
            .selector__control {
                @apply border-2 border-danger ring-danger focus:border-danger focus:ring-danger focus:ring-1;
            }
        }
    }

    &.md {
        .selector-container {
            .selector__control {
                @apply text-sm;
                min-height: 41px;

                .selector__value-container {
                    @apply px-2;
                }

                .selector__indicators {
                    .selector__indicator {
                        svg path {
                            @apply fill-gray-500;
                        }
                    }
                }
            }
        }
    }

    &.sm {
        .selector-container {
            .selector__control {
                @apply text-xs border-none;
                min-height: 34px;

                .selector__indicators {
                    .selector__indicator {
                        padding: 1px;
                    }
                }
            }
        }
    }

    &.border-sm {
        .selector-container {
            .selector__control {
                @apply text-xs rounded-lg;
                min-height: 28px;

                .selector__indicators {
                    .selector__indicator {
                        padding: 1px;
                    }
                }
            }
        }
    }

    &.xs {
        .selector-container {
            .selector__control {
                @apply text-xs;
                min-height: 0;

                .selector__indicators {
                    display: none;

                    .selector__indicator {
                        display: none;
                    }
                }
            }
        }
    }

    .selector-container {
        .selector__control {
            @apply w-full border-black border-opacity-30 rounded-[0.313rem];
            .selector__value-container {
                padding: 0 0.2rem;

                .selector__single-value {
                    margin: 0;
                }

                .selector__input-container {
                    margin: 0;

                    input {
                        @apply outline-none ring-0 py-1;
                    }
                }

                .selector__placeholder {
                    @apply text-black opacity-40;
                }

                .selector__multi-value {
                    @apply flex items-center font-medium rounded-[10px] px-2.5 py-0.5;
                    .selector__multi-value__label {
                        @apply p-0;
                    }

                    &.selector__multi-value--is-disabled {
                        @apply text-gray-500/70 bg-gray-200;
                        .selector__multi-value__label {
                            @apply text-gray-500/70;
                        }
                    }
                }

                .selector__single-value {
                    &.selector__single-value--is-disabled {
                        @apply text-gray-750;
                    }
                }
            }

            .selector__indicators {
                .selector__indicator-separator {
                    display: none;
                }
                .selector__indicator {
                    padding: 3px;
                    svg {
                        @apply fill-gray-500;
                    }
                }
            }

            &.selector__control--is-disabled {
                @apply text-gray-750 bg-gray-200 border-transparent #{!important};
            }

            .selector__indicators {
                .selector__indicator-separator {
                    display: none;
                }
                .selector__indicator {
                    padding: 3px;
                    svg {
                        @apply fill-gray-400;
                    }
                }
            }
        }

        .selector__menu {
            @apply mt-[-4px] shadow-lg rounded-md py-1 text-base sm:text-sm;
            .selector__menu-list {
                .selector__option {
                    @apply py-2 pl-3 pr-9 text-gray-800;
                }
            }
        }
    }
}
