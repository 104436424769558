.demand-item-table-header {
    @apply inline-flex justify-center items-center gap-1;

    .demand-item-table-header-icon {
        @apply h-6 w-6;
    }
}

.demand-item-table-header-tooltip-content {
    a {
        text-decoration: underline !important;
    }
}

.demand-item-table {
    @apply border border-gray-400 overflow-hidden bg-gray-100 select-none relative;

    &.rounded {
        @apply rounded-2xl;
    }

    [data-attribute='notice'],
    [data-attribute='Notice'] {
        @apply italic;
    }

    .simplebar-scrollbar::before {
        @apply transition-opacity duration-75 opacity-[0.4];
    }

    .simplebar-dragging .simplebar-scrollbar::before,
    .simplebar-scrollbar.simplebar-hover::before {
        @apply opacity-[0.5];
    }

    .simplebar-track.simplebar-vertical {
        @apply w-[20px];
    }

    .simplebar-track.simplebar-horizontal {
        @apply h-[20px];
    }

    &.top-content {
        thead::before {
            & {
                @apply absolute z-[-1] bottom-[-14px] w-full h-[14px];
                @apply transition-all duration-100;
                content: '';
                background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.3));
            }
        }
    }

    &.bottom-content {
        tfoot::after {
            & {
                @apply absolute z-[-1] top-[-14px] w-full h-[16px];
                @apply transition-all duration-100;
                content: '';
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.3));
            }
        }
    }

    &.right-content::before {
        @apply absolute z-[1] top-0 right-[20px] bottom-[1.25rem] w-[1rem];
        @apply transition-all duration-100;
        content: '';
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.3));
    }

    &.right-content.right-content-reset::before {
        @apply right-0;
    }

    &.left-content::after {
        @apply absolute z-[1] top-0 left-[--demand-create-items-table--left-offset] bottom-[1.25rem] w-[1rem];
        @apply transition-all duration-100;
        content: '';
        background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.3));
    }

    &.left-content.reset-left-content::after {
        @apply left-0;
    }
}
