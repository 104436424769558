.dropdown-menu {
    @apply pt-1;
}

.dropdown-menu .dropdown-menu-container {
    @apply rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;
}

.dropdown-menu .dropdown-menu-container .dropdown-menu-item {
    @apply flex items-center w-full text-gray-500 hover:text-black block px-3.5 py-3 text-sm text-body-regular font-normal;
}

.dropdown-menu .dropdown-menu-container .dropdown-menu-item:not(.disable-hover-effect) {
    @apply hover:bg-primary hover:bg-opacity-5;
}

.dropdown-menu .dropdown-menu-container .dropdown-menu-item.disable-hover-effect {
    @apply cursor-default;
}

.dropdown-menu .dropdown-menu-container .dropdown-menu-item.active {
    @apply bg-primary text-white hover:bg-primary;
}

.dropdown-menu .dropdown-menu-container .dropdown-menu-item.bordered:not(:last-child),
.dropdown-menu.bordered .dropdown-menu-container .dropdown-menu-item:not(:last-child) {
    @apply border-b border-b-gray-400;
}

.dropdown-menu .dropdown-menu-container .dropdown-menu-item .icon {
    @apply block -ml-0.5 mr-3 h-5 w-5;

    svg {
        @apply text-current w-full h-full;
    }
}
