.plain-tabs {
    @apply overflow-x-auto relative divide-x divide-gray-200 rounded-t-lg my-3 mb-5;
}

.plain-tabs .plain-tab {
    @apply whitespace-nowrap relative flex-1 bg-white  px-11 md:px-8 focus:z-10 transition-all duration-200 h-[41px];
    @apply text-gray-700 text-center text-[15px] font-normal;
    @apply hover:text-gray-700 hover:bg-gray-200 hover:rounded-lg;
}

.plain-tab {
    @apply mr-2 outline-none #{!important};
}

.plain-tabs .plain-tab-icon * {
    @apply -ml-0.5 mr-2 h-5 w-5;
}

.plain-tabs .plain-tab.active {
    @apply text-gray-700 bg-gray-200 rounded-lg font-normal;
}

.plain-tabs .plain-tab .plain-tab-line {
    @apply bg-transparent absolute inset-x-0 bottom-0 h-0.5;
}

.plain-tabs .plain-tab.secondary.active .plain-tab-line {
    @apply bg-secondary;
}
