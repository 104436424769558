.textarea {
    @apply pt-2;
}

.textarea textarea {
    @apply shadow-sm block w-full text-sm rounded-md border-black border-opacity-30;
}

.textarea textarea::placeholder {
    @apply font-normal text-sm text-black opacity-30;
}

.textarea label {
    @apply block text-sm font-normal text-gray-600;
}

.textarea.primary textarea {
    @apply focus:ring-primary focus:border-primary;
}

.textarea.invalid textarea {
    @apply border-2 border-danger ring-danger focus:border-danger focus:ring-danger;
}

.textarea.invalid label {
    @apply text-danger;
}

.textarea .textarea-message {
    @apply text-gray-400 text-xs mt-1;
}

.textarea.invalid .textarea-message {
    @apply text-danger;
}

.textarea textarea:disabled {
    @apply text-gray-400 bg-gray-200 border-black border-opacity-30 #{!important};
}

.textarea.md textarea {
    @apply leading-4;
    min-height: 41px;
}

.textarea.lg textarea {
    @apply h-full;
}

.textarea.sm textarea {
    @apply px-1 py-2 text-xs #{!important};
}
