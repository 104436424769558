.loading {
    @apply w-12 h-12 border-2;
    color: white;
    position: relative;
    display: inline-block;
    border: solid;
    border-radius: 50%;
    border-top-color: transparent;
    animation: rotate 0.75s linear infinite;
}

.loading.primary {
    @apply text-primary;
}

.loading.secondary {
    @apply text-secondary;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-bar {
    position: relative;
    width: 100%;
    height: 0.313rem;
    overflow-x: hidden;

    .line {
        position: absolute;
        opacity: 0.4;
        width: 150%;
        height: 0.313rem;
    }

    .sub-line {
        position: absolute;
        height: 0.313rem;
    }

    .inc {
        animation: increase 2s infinite;
    }

    .dec {
        animation: decrease 2s 0.5s infinite;
    }

    &.primary {
        .line,
        .sub-line {
            @apply bg-primary;
        }
    }

    &.secondary {
        .line,
        .sub-line {
            @apply bg-secondary;
        }
    }
}

@keyframes increase {
    from {
        left: -5%;
        width: 5%;
    }

    to {
        left: 130%;
        width: 100%;
    }
}

@keyframes decrease {
    from {
        left: -80%;
        width: 80%;
    }

    to {
        left: 110%;
        width: 10%;
    }
}
